<template>
  <div>
    <v-dialog v-model="value" fullscreen>
      <v-card :loading="loadingMain" :disabled="loadingMain">
        <template slot="progress">
          <v-progress-linear color="primary" indeterminate></v-progress-linear>
        </template>
        <BarTitle @close="close()" title="MEDIÇÃO" />
        <v-card-actions>
          <v-row no-gutters>
            <v-col class="text-left">
              <v-btn fab color="primary" @click="exportPdf()" v-bind="attrs" v-on="on"
                ><v-icon large> mdi-file-pdf-box </v-icon></v-btn
              >
              <!-- <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon x-large color="primary" outlined v-bind="attrs" v-on="on"><v-icon> mdi-menu </v-icon></v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title @click="exportPdf()"
                      ><v-icon>mdi-file-pdf-box</v-icon> Exportar Pdf</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu> -->
            </v-col>
            <v-col class="text-right">
              <v-btn
                :loading="loadingApproved"
                :disabled="loadingApproved || approved"
                @click="approvedContract()"
                x-large
                color="success"
                class="mr-8"
                >aprovar</v-btn
              >
              <!-- </v-col> -->
              <!-- <v-col class="text-right"> -->
              <!-- <v-btn v-if="!approved" text x-large>fechar</v-btn> -->
              <v-btn
                :loading="loadingSave"
                :disabled="loadingSave || approved"
                @click="save('close')"
                x-large
                color="primary"
                >SALVAR</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                dense
                :disabled="loadingContractors || approved"
                :loading="loadingContractors"
                hide-details
                class=""
                v-model="selectedContractor"
                return-object
                :items="contractors"
                item-value="id"
                item-text="fantasy_name"
                outlined
                label="Empreiteiro"
                :messages-errors="errors.contractor"
              ></v-select>
              <!-- <b>SUB-EMPRETEIRO :</b> {{ contractor.fantasy_name }} -->
            </v-col>
            <v-col>
              <v-text-field
                dense
                hide-details
                label="Período"
                :disabled="true"
                v-model="formatPeriod"
                outlined
              ></v-text-field>
              <!-- <b>PERÍODO :</b> -->
              <!-- {{
                formatDate(contract.date_start) + " - " + formatDate(contract.date_end)
              }} -->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                dense
                hide-details
                label="Medição N°"
                :disabled="approved"
                outlined
                v-model="contract.metering_n"
              ></v-text-field>
              <!-- <div style="white-space: nowrap">
                <div v-if="approved" class="">
                  <b>MEDICÃO N°:</b> {{ contract.metering_n }}
                </div>
                <v-edit-dialog
                  v-if="!approved"
                  :return-value.sync="contract.metering_n"
                  large
                  cancel-text="fechar"
                  save-text="salvar"
                >
                  <div class="editable"><b>MEDICÃO N°:</b> {{ contract.metering_n }}</div>
                  <template v-slot:input>
                    <v-text-field v-model="contract.metering_n"></v-text-field>
                  </template>
                </v-edit-dialog>
              </div> -->
            </v-col>
            <v-col>
              <v-text-field
                dense
                hide-details
                label="Data Aprovação"
                :disabled="true"
                v-model="formatTextApproved"
                outlined
              ></v-text-field>
              <!-- <b>DATA:</b> {{ formatDateTime(contract.date_approved) }} <b>Por:</b> -->
              <!-- {{ userApproved }} -->
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card outlined>
                <v-data-table
                  dense
                  :headers="headers"
                  :items="contractSummaryItems"
                  disable-pagination
                  hide-default-footer

                  class="elevation-0"
                >
                  <template v-slot:top>
                    <v-toolbar flat dense>
                      <v-toolbar-title>Serviços</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="dialogAddServiceDetached()"
                        :disabled="approved"
                        dense
                        rounded
                        color="primary"
                        ><v-icon>mdi-plus</v-icon>Serviço
                      </v-btn>
                    </v-toolbar>
                  </template>

                  <!-- ITEM -->
                  <template v-slot:item.item="{ item }">
                    {{ contractSummaryItems.indexOf(item) + 1 }}
                  </template>
                  <!-- QUANTIDADE -->
                  <template v-slot:item.quantity="{ item }">
                    {{ formatMoney(item.quantity) }}
                  </template>

                  <!-- PRECO UNITARIO -->
                  <template v-slot:item.unit_price="{ item }">
                    {{ formatMoney(item.unit_price) }}
                  </template>

                  <template v-slot:item.sub_total="{ item }">
                    {{ formatMoney(item.sub_total) }}
                  </template>
                  <template v-slot:no-data> Nenhum item na lista. </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn color="error" icon @click="removeService(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:foot>
                    <tr>
                      <td
                        style="background: lightgray; height: 35px"
                        class="text-right"
                        colspan="6"
                      >
                        SUB-TOTAL:
                      </td>
                      <td
                        style="
                          background: lightgray;
                          height: 35px;
                          padding-left: 16px;
                          padding-right: 16px;
                        "
                        class="text-right"
                      >
                        <b>{{ formatMoney(subtotalContractorItems()) }}</b>
                      </td>
                    </tr>
                    <!-- <tr>
                                    <td class="text-center " colspan='8'>
                                        <v-btn @click="addService()" class="mb-4 mt-4" rounded color="primary">
                                            <v-icon>mdi-plus</v-icon>ADICIONAR SERVIÇO
                                        </v-btn>
                                    </td>
                                </tr> -->
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="cols-4">
              <v-textarea
                hide-details
                v-model="contract.obs"
                rows="12"
                label="Observações"
                class=""
                outlined
              ></v-textarea>
            </v-col>
            <v-col>
              <v-card outlined style="font-size: 16px">
                <v-row
                  style="font-size: 18px; background: #1976d2; color: white; height: 30px"
                  no-gutters
                >
                  <v-col cols="4"><b class="ml-2">SUBTOTAL 1</b></v-col>
                  <v-col cols="2" class="text-right" style="font-size: 14px">QUANT</v-col>
                  <v-col cols="3" class="text-right" style="font-size: 14px">P. UNIT</v-col>
                  <v-col cols="3" class="text-right"
                    ><b class="mr-2">{{
                      formatMoney(subtotalContractorItems())
                    }}</b></v-col
                  >
                </v-row>
                <v-row
                  v-for="(item, index) in metering_contract_expenses"
                  :key="index"
                  style="background: lightgray; height: 25px"
                  no-gutters
                >

                  <v-col cols="4">
                    <v-row no-gutters>
                      <v-col>
                        <div v-if="approved" class="ml-2">{{ item.description }}</div>
                        <v-edit-dialog
                          v-if="!approved"
                          :return-value.sync="item.description"
                          large
                          cancel-text="fechar"
                          save-text="salvar"
                        >
                          <div class="editable ml-2">{{ item.description }}</div>
                          <template v-slot:input>
                            <v-text-field v-model="item.description"></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="2" class="text-right">
                    <div v-if="approved" class="">{{ formatMoney(item.base) }}</div>
                    <v-edit-dialog
                      v-if="!approved"
                      @save="calcTotal()"
                      :return-value.sync="item.base"
                      large
                      cancel-text="fechar"
                      save-text="salvar"
                    >
                      <div class="editable">{{ formatMoney(item.base) }}</div>
                      <template v-slot:input>
                        <vuetify-money
                          v-model="item.base"
                          :clearable="true"
                          valueWhenIsEmpty="0"
                          :options="{
                            locale: 'pt-BR',
                            length: 11,
                            precision: 2,
                          }"
                        />
                      </template>
                    </v-edit-dialog>
                  </v-col>

                  <v-col cols="3" class="text-right">
                    <v-row no-gutters>
                      <v-col>
                        <a text icon @click="invertSinal(item)"
                          >(<v-icon>mdi-minus</v-icon>)</a
                        >
                      </v-col>
                      <v-col>
                        <div v-if="approved" class="">{{ formatMoney(item.fator) }}</div>
                        <v-edit-dialog
                          v-if="!approved"
                          @save="calcTotal()"
                          :return-value.sync="item.fator"
                          large
                          cancel-text="fechar"
                          save-text="salvar"
                        >
                          <div class="editable">{{ formatMoney(item.fator) }}</div>
                          <template v-slot:input>
                            <vuetify-money
                              v-model="item.fator"
                              :clearable="true"
                              valueWhenIsEmpty="0"
                              :options="{
                                locale: 'pt-BR',
                                length: 11,
                                precision: 2,
                              }"
                            />
                          </template>
                        </v-edit-dialog>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="3" class="text-right">
                    <b class="mr-2">{{ formatMoney(item.sub_total) }}</b>
                  </v-col>
                </v-row>

                <v-row
                  style="font-size: 18px; background: #1976d2; color: white; height: 30px"
                  no-gutters
                >
                  <v-col cols="4"><b class="ml-2">SUBTOTAL 2</b></v-col>
                  <v-col cols="2" class="text-right" style="font-size: 14px">BASE</v-col>
                  <v-col cols="3" class="text-right" style="font-size: 14px"
                    >ALÍQUOTA</v-col
                  >
                  <v-col cols="3" class="text-right"
                    ><b class="mr-2">{{ formatMoney(subTotal2) }}</b></v-col
                  >
                </v-row>
                <v-row
                  v-for="(item, index) in metering_contract_taxes"
                  :key="index"
                  style="background: lightgray; height: 25px"
                  no-gutters
                >
                  <v-col cols="4">
                    <div v-if="approved" class="ml-2">{{ item.description }}</div>
                    <v-edit-dialog
                      v-if="!approved"
                      :return-value.sync="item.description"
                      large
                      cancel-text="fechar"
                      save-text="salvar"
                    >
                      <div class="editable ml-2">{{ item.description }}</div>
                      <template v-slot:input>
                        <v-text-field v-model="item.description"></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </v-col>

                  <v-col cols="2" class="text-right">
                    <div v-if="approved" class="">{{ formatMoney(item.base) }}</div>
                    <v-edit-dialog
                      v-if="!approved"
                      @save="calcTotal()"
                      :return-value.sync="item.base"
                      large
                      cancel-text="fechar"
                      save-text="salvar"
                    >
                      <div class="editable">{{ formatMoney(item.base) }}</div>
                      <template v-slot:input>
                        <vuetify-money
                          v-model="item.base"
                          :clearable="true"
                          valueWhenIsEmpty="0"
                          :options="{
                            locale: 'pt-BR',
                            length: 11,
                            precision: 2,
                          }"
                        />
                      </template>
                    </v-edit-dialog>
                  </v-col>

                  <v-col cols="3" class="text-right">
                    <v-row no-gutters>
                      <v-col  class="text-right">
                        <a text icon @click="invertSinal(item)">
                          (<v-icon>mdi-minus</v-icon>)</a
                        >
                      </v-col>
                      <v-col >
                        <div v-if="approved" class="">
                          {{ formatMoney(item.fator) }} %
                        </div>
                        <v-edit-dialog
                          v-if="!approved"
                          @save="calcTotal()"
                          :return-value.sync="item.fator"
                          large
                          cancel-text="fechar"
                          save-text="salvar"
                        >
                          <div class="editable">{{ formatMoney(item.fator) }} %</div>
                          <template v-slot:input>
                            <vuetify-money
                              v-model="item.fator"
                              :clearable="true"
                              valueWhenIsEmpty="0"
                              :options="{
                                locale: 'pt-BR',
                                length: 11,
                                precision: 2,
                              }"
                            />
                          </template>
                        </v-edit-dialog>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="3" class="text-right">
                    <b class="mr-2">{{ formatMoney(item.sub_total) }}</b>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  style="
                    font-size: 18px;
                    font-weight: bold;
                    background: #1976d2;
                    color: white;
                    height: 30px;
                  "
                >
                  <v-col class="ml-2">TOTAL:</v-col>
                  <v-col class="text-right mr-2"> {{ formatMoney(total) }}</v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <!-- <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn
                v-if="!approved"
                :loading="loadingApproved"
                :disabled="loadingApproved"
                @click="approvedContract()"
                x-large
                color="success"
                >aprovar</v-btn
              >
            </v-col>
            <v-col class="text-right">
              <v-btn v-if="!approved" text x-large>fechar</v-btn>
              <v-btn
                v-if="!approved"
                :loading="loadingSave"
                :disabled="loadingSave"
                @click="save('close')"
                x-large
                color="primary"
                >SALVAR</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <!-- ADICIONAR SERVIÇO -->
    <AddService
      @confirm="addServiceDetached"
      :contractor="selectedContractor"
      v-model="dialogAddService"
    ></AddService>
  </div>
</template>

<script>
import BarTitle from "@/pages/Components/BarTitle.vue";
import AddService from "./DialogAddService";
import { mapActions } from "vuex";
import units from "@/plugins/units.js";

export default {
  props: {
    value: {
      type: Boolean,
    },
    contractor: {
      type: Object,
    },
  },
  components: {
    BarTitle,
    AddService,
  },

  data: () => ({
    selectedContractor: {},
    loadingContractors: false,
    contractors: [],
    dialogAddService: false,
    approved: false,
    userApproved: "",
    total: 0,
    subTotal2: 0,
    subTotal1: 0,
    itemsUnits: units.units,
    loadingMain: false,
    loadingSave: false,
    loadingApproved: false,
    newServicesDetached: [],
    contract: {
      obs: "",
      metering_n: "",
      date_approved: "",
      date_start: "",
      date_end: "",
    },
    contractSummaryItems: [],
    metering_contract_item_deleted: [],

    metering_contract_expenses: [],
    metering_contract_taxes: [],
    errors: {
      contractor: null,
    },
    headers: [
      {
        text: "ITEM",
        value: "item",
      },
      {
        text: "CC",
        value: "cc",
      },
      {
        text: "SERVIÇO",
        value: "service",
      },
      {
        text: "UND",
        value: "unity",
      },
      {
        text: "QUANTIDADE",
        value: "quantity",
        align: "right",
      },
      {
        text: "P. UNIT",
        value: "unit_price",
        align: "right",
      },
      {
        text: "SUB-TOTAL",
        value: "sub_total",
        align: "right",
      },
      // {
      //     text: '',
      //     value: 'actions',
      //     aling: 'end',
      // }
    ],
    headers_subtotal1: [
      {
        text: "SUB-TOTAL 1",
        value: "",
      },
      {
        text: "",
        value: "",
      },
      {
        text: "",
        value: "",
      },
      {
        text: "",
        value: "",
      },
    ],
  }),

  watch: {
    value(val) {
      if (val) {
        this.initialize();
      }
    },
  },
  computed: {
    formatPeriod() {
      if (this.contract.date_start) {
        return (
          this.formatDate(this.contract.date_start) +
          " - " +
          this.formatDate(this.contract.date_end)
        );
      }
      return "";
    },
    formatTextApproved() {
      if (this.contract.date_approved) {
        return (
          this.formatDateTime(this.contract.date_approved) +
          "   Por: " +
          this.userApproved
        );
      }

      return "";
    },
  },

  methods: {
    ...mapActions("Metering", [
      "getContractSummary",
      "updateCreateContractorSummary",
      "approvedServices",
      "getExportPdf",
      "getContractors",
    ]),
    initialize() {
      //define items depesas padrao
      this.metering_contract_expenses = [
        {
          description: "ALMOÇO",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "CAFÉ",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "EPI",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "OUTROS",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
      ];

      // define items de impostos padrao
      this.metering_contract_taxes = [
        {
          description: "ISS (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "INSS (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "PIS (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "COFINS (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "CSLL (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "IR (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
      ];
      //limpa o array de item deletados
      this.metering_contract_item_deleted = [];
      //limpa array de novos serviços avulsos
      this.newServicesDetached = [];
      this.contractors = [];
      this.contractSummaryItems = [];
      this.selectedContractor = {};
      this.approved = false;
      this.userApproved = "";
      this.contract = {};

      if (this.contractor.id) {
        this.contractors.push(this.contractor);
        this.selectedContractor = this.contractor;

        this.loadingMain = true;
        this.getContractSummary(this.contractor.id)
          .then((response) => {
            if (response.data.metering_contract) {
              this.contract = response.data.metering_contract;

              // se o contracto for aprovado
              this.contract.approved == "yes"
                ? (this.approved = true)
                : (this.approved = false);

              this.contract.approved_by
                ? (this.userApproved = this.contract.approved_by_user.name)
                : "";

              if (this.contract.metering_contract_expenses.length > 0) {
                this.metering_contract_expenses = this.contract.metering_contract_expenses;
              }

              if (this.contract.metering_contract_taxes.length > 0) {
                this.metering_contract_taxes = this.contract.metering_contract_taxes;
              }

              this.contractSummaryItems = response.data.items;

              if (this.contractSummaryItems.length > 0) {
                this.contract.date_start = this.contractSummaryItems[0].date_start;
                this.contract.date_end = this.contractSummaryItems[0].date_end;
              }

              //define o periodo
              // this.contract.date_start = this.contractSummaryItems[0].date_start;
              // this.contract.date_end = this.contractSummaryItems[0].date_end;

              this.calcTotal();
            }
          })
          .finally(() => {
            this.loadingMain = false;
          });
      } else {
        // carrega os empreiteiros
        this.loadingContractors = true;
        this.getContractors()
          .then((response) => {
            this.contractors = response.data;
          })
          .finally(() => {
            this.loadingContractors = false;
          });
      }
    },
    close() {
      this.$emit("input", false);
    },

    subtotalContractorItems() {
      const result = this.contractSummaryItems.reduce((acc, item) => {
        return acc + parseFloat(item.sub_total);
      }, 0);

      return result;
    },
    dialogAddServiceDetached() {
      //valida se um empreiteiro foi escolhido
      if (!(Object.keys(this.selectedContractor).length > 0)) {
        this.$toast.info(
          "É obrigatorio escolher um empreiteiro antes de adicionar um serviço. "
        );
        this.errors.contractor = "Obrigatório";
        return;
      }

      // this.contractor = this.contractor ? this.contractor: this.selectedContractor
      // console.log(this.contractor)
      this.dialogAddService = true;
    },
    addServiceDetached(service) {
      let serviceDetached = {
        order_service_item_id: service.service.order_service_items_id,
        stage_id: service.stage.id,
        cc: service.stage.code + " - " + service.stage.name,
        quantity: service.quantity,
        service: service.service.description,
        unit_price: service.service.unit_cost,
        sub_total: service.quantity * service.service.unit_cost,
        unity: service.service.unit_of_measure,
      };
      this.newServicesDetached.push(serviceDetached);
      this.contractSummaryItems.push(serviceDetached);
    },
    // setPeriod() {
    //   if (this.contract) {
    //     this.dateStart = this.contract.items[0].date_start;
    //     this.dateEnd = this.contract.items[0].date_end;
    //   }
    // },
    exportPdf() {
      this.getExportPdf(this.contractor)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "export_summary_contractor.pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .finally(() => {});

      this.$toast.success("Exportando PDF");
    },
    /**
     * SALVAR O CONTRATO E SEUS ITEMS
     */
    save($param) {
      //   validacao
      if (this.contract.metering_n == "" || this.contract.metering_n == null) {
        this.$toast.error("Campo Medição é obrigatório");
        return false;
      }

      this.loadingSave = true;

      let contractor_id = this.contractor.id
        ? this.contractor.id
        : this.selectedContractor.id;

      const data = {
        contract_id: this.contract.id,
        contractor_id: contractor_id,
        obs: this.contract.obs,
        metering_contract_expenses: this.metering_contract_expenses,
        metering_contract_taxes: this.metering_contract_taxes,
        date_start: this.contract.date_start,
        date_end: this.contract.date_end,
        metering_n: this.contract.metering_n,
        new_services_detached: this.newServicesDetached,
      };
      this.updateCreateContractorSummary(data)
        .then(() => {
          this.$toast.success("Salvo com sucesso.");
          switch ($param) {
            case "close":
              this.$emit("input", false);
              break;
            case "approved":
              this.sendApproved();
              break;
          }
        })
        .catch((error) => {
          if (error.response.status == 423) {
            this.$toast.error(error.response.data);
          }
        })
        .finally(() => {
          this.loadingSave = false;
        });

      // this.contract.local_metering_id = this.metering.id
      // this.contract.metering_contract_item = this.metering_contract_item
      // this.contract.metering_contract_expenses = this.metering_contract_expenses
      // this.contract.metering_contract_taxes = this.metering_contract_taxes

      // const data = {
      //     contract: this.contract,
      //     metering_contract_item_deleted: this.metering_contract_item_deleted,
      // }

      // this.updateCreateMeteringContract(data).then(() => {
      //         this.$toast.success('Salvo com sucesso')
      //         this.close()
      //     })
      //     .finally(() => {
      //         this.loadingSave = false
      //     })
    },

    /**
     * Inverte Sinal
     */
    invertSinal(item) {
      item.fator = item.fator * -1;
      this.calcTotal();
    },
    /**
     * APROVAR SERVIÇOS DO EMREITEIRO EM UM PERIODO ESPECIFICO
     */

    approvedContract() {
      this.save("approved");
    },
    sendApproved() {
      this.loadingApproved = true;
      this.approvedServices(this.contractor.id)
        .then(() => {
          //fechar o modal de controle de medicoes
          this.$emit("refreshTable");
          this.close();
          this.$toast.success("Controle de medição aprovado. ");
        })
        .finally(() => {
          this.loadingApproved = false;
        });
    },
    /**
     * ADICIONA NOVO SERVIÇO
     */
    showAddService() {
      this.dialogAddService = true;
    },

    /**
     * REMOVE UM ITEM DE SERVICOS
     */
    removeService(service) {
      this.metering_contract_item_deleted.push(Object.assign({}, service));
      const index = this.metering_contract_item.indexOf(service);
      this.metering_contract_item.splice(index, 1);
    },

    /**
     *CALCULA OS SUB-TOTAIS DE CADA SERVIÇO
     */
    calcTotal() {
      //sub total de cada linha do SUBTOTAL1
      this.metering_contract_expenses.forEach((item) => {
        item.sub_total = parseFloat(item.base) * parseFloat(item.fator);
      });

      //sub total de cada linha do SUBTOTAL2
      this.metering_contract_taxes.forEach((item) => {
        item.sub_total = parseFloat(item.base) * (parseFloat(item.fator) / 100);
      });

      //somatorio de subtotais do SUBTOTAL1
      let sumSubtotal1 = this.metering_contract_expenses.reduce((acc, item) => {
        return acc + parseFloat(item.sub_total);
      }, 0);

      this.subTotal2 = this.subtotalContractorItems() - parseFloat(sumSubtotal1);

      //somatorio de subtotais do SUBTOTAL2
      let sumSubtotal2 = this.metering_contract_taxes.reduce((acc, item) => {
        return acc + parseFloat(item.sub_total);
      }, 0);

      //total
      this.total = parseFloat(this.subTotal2) - parseFloat(sumSubtotal2);
    },

    /**
     * FORMATA FLOAT PARA MONETARIO
     * @param number
     */
    formatMoney(number) {
      if (isNaN(number)) {
        return 0;
      }
      number = parseFloat(number);

      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    formatDate(date) {
      if (date) {
        let date_split = date.split("-");
        return date_split[2] + "/" + date_split[1] + "/" + date_split[0];
      }
      return "";
    },
    formatDateTime(date) {
      if (date) {
        date = new Date(date);
        let dateTime =
          date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear() + " ";
        dateTime += date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        return dateTime;
      }

      return "";
    },
  },
};
</script>

<style scoped>
.editable {
  color: #1976d2;
  font-weight: bold;
}
</style>
